import { useEffect } from "react";
import { useGlobalStore } from "../../../../store";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

interface RedirectHandlerProps {
  children: React.ReactNode;
}

let previousLocation = "";

export default function RedirectHandler({ children }: RedirectHandlerProps) {
  const location = useLocation();
  const locationState = location.state as {
    from: string;
    isRedirected: boolean;
  };

  const { state, dispatch } = useGlobalStore();

  const [searchParams] = useSearchParams();
  const redirectedFromQueryParam = "redirectedFrom";

  useEffect(() => {
    const redirectedFrom = searchParams.get(redirectedFromQueryParam);
    if (
      (!state.isUserLoggedIn && locationState?.isRedirected) ||
      redirectedFrom
    ) {
      if (redirectedFrom) {
        previousLocation = redirectedFrom;
      }
      dispatch({
        type: "SET_REDIRECT_LINK",
        payload: previousLocation,
      });
    }
    previousLocation = location.pathname + location.search;
  }, [location]);

  return <>{children}</>;
}
