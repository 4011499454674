import { useMachine } from "@xstate/react";
import { pathologyFlowStateMachine, eventNames } from "./machine";
import FullscreenLoadingIndicator from "@/features/Common/components/FullscreenLoadingIndicator";
import PathologyPanels from "../PathologyPanels";
import PathologyPanelDetails from "../PathologyPanelDetails";
import { PathologyPanel } from "@/services/core-api-adapter";
import { PathologyPrompt } from "../PathologyPrompt";

export default function PathologyFlow() {
  const [pathologyStateMachine, pathologyStateMachineDispatch] = useMachine(
    pathologyFlowStateMachine
  );

  function handlePanelClick(panel: PathologyPanel) {
    pathologyStateMachineDispatch({
      type: eventNames.BLOOD_PANEL_SELECTED,
      input: panel,
    });
  }

  function handleBuyButtonClick() {
    pathologyStateMachineDispatch({
      type: eventNames.BUY_NOW_BUTTON_CLICKED,
    });
  }

  function handleBackButtonClick() {
    pathologyStateMachineDispatch({
      type: eventNames.BACK_BUTTON_CLICKED,
    });
  }

  function handlePromptBackButtonClick() {
    pathologyStateMachineDispatch({
      type: eventNames.BACK_BUTTON_CLICKED,
    });
  }

  return (
    <>
      {pathologyStateMachine.matches("fetchingBloodPanelList") ||
        (pathologyStateMachine.matches("fetchingBloodPanelDetails") && (
          <FullscreenLoadingIndicator />
        ))}

      {pathologyStateMachine.matches("showingBloodPanelList") && (
        <PathologyPanels
          panelList={pathologyStateMachine.context.bloodPanelListResponse}
          onPanelClick={handlePanelClick}
        />
      )}

      {pathologyStateMachine.matches("showingBloodPanelDetails") &&
        pathologyStateMachine.context.selectedBloodPanel && (
          <PathologyPanelDetails
            onBuyButtonClick={handleBuyButtonClick}
            onBackButtonClick={handleBackButtonClick}
            panel={pathologyStateMachine.context.selectedBloodPanel}
          />
        )}

      {pathologyStateMachine.matches("showingPrompt") && (
        <PathologyPrompt
          onContinueButtonClick={handleBuyButtonClick}
          onBackButtonClick={handlePromptBackButtonClick}
        />
      )}
    </>
  );
}
