import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./services/feature-toggle-adapter";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import App from "./app";

const container = document.getElementById("root");
const root = createRoot(container as Element);

console.log(window.location.toString());

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();
