import { Navigate, Outlet } from "react-router-dom";
import { useGlobalStore } from "../../../../store";

interface Props {
  to?: string;
}

export default function RedirectIfNotAuthenticated(props: Props) {
  const { state } = useGlobalStore();
  const redirectTo = props.to ? props.to : "/login";

  return !state.isUserLoggedIn ? (
    <Navigate to={redirectTo} replace state={{ isRedirected: true }} />
  ) : (
    <Outlet />
  );
}
