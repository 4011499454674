import {
  getPathologyPanelsList,
  PathologyPanel,
} from "@/services/core-api-adapter";
import { assign, fromPromise, setup } from "xstate";

interface Context {
  orderDetails: any;
  bloodPanelListResponse: PathologyPanel[];
  bloodPanelDetailsResponse: any;
  selectedBloodPanel: PathologyPanel | null;
}

const initialContextValues: Context = {
  orderDetails: {},
  bloodPanelListResponse: [],
  bloodPanelDetailsResponse: {},
  selectedBloodPanel: null,
};

export const eventNames = {
  BACK_BUTTON_CLICKED: "BACK_BUTTON_CLICKED",
  BUY_NOW_BUTTON_CLICKED: "BUY_NOW_BUTTON_CLICKED",
  CONTINUE_BUTTON_CLICKED: "CONTINUE_BUTTON_CLICKED",
  USER_REDIRECTED: "USER_REDIRECTED",
  BLOOD_PANEL_SELECTED: "BLOOD_PANEL_SELECTED",
};

export const pathologyFlowStateMachine = setup({
  types: {
    context: {} as Context,
  },
  actions: {
    addOrderDetailsToContext: function () {
      return {};
    },

    addBloodPanelListResponseToContext: assign(({ event }) => {
      return {
        bloodPanelListResponse: event.output,
      };
    }),

    addSelectedBloodPanelToContext: assign(({ event }) => {
      return {
        selectedBloodPanel: event.input,
      };
    }),

    addBloodPanelDetailsResponseToContext: assign(({ event }) => {
      return {
        bloodPanelDetailsResponse: event.output,
      };
    }),
  },
  actors: {
    getBloodPanelById: fromPromise(async () => {
      return {};
    }),
    getBloodPanelList: fromPromise(async () => {
      return getPathologyPanelsList();
    }),
    getBloodPanelDetails: fromPromise(async () => {
      return {};
    }),
    createOrder: fromPromise(async () => {
      return {};
    }),
    fetchOrderDetails: fromPromise(async () => {
      return {};
    }),
  },
  guards: {
    hasBloodPanelIdInQueryParams: function () {
      return false;
    },
    ifHasBloodPanelIdInQueryParams: function () {
      return true;
    },
    paymentHasFailed: function () {
      return true;
    },
    hasOrderNumber: function () {
      return true;
    },
    hasNoOrderNumber: function () {
      return true;
    },
    timeOutExceeded: function () {
      return true;
    },
  },
  schemas: {},
}).createMachine({
  context: initialContextValues,
  id: "pathologyFlow",
  initial: "showingBloodPanelListOrShowingBloodPanelDetails",
  states: {
    showingBloodPanelListOrShowingBloodPanelDetails: {
      always: [
        {
          target: "fetchingBloodPanelListDetails",
          guard: {
            type: "hasBloodPanelIdInQueryParams",
          },
        },
        {
          target: "fetchingBloodPanelList",
        },
      ],
    },
    fetchingBloodPanelListDetails: {
      invoke: {
        input: {
          bloodPanelId: "string",
        },
        onDone: {
          target: "showingBloodPanelDetails",
        },
        onError: {
          target: "error",
        },
        src: "getBloodPanelById",
      },
    },
    fetchingBloodPanelList: {
      invoke: {
        input: {},
        onDone: {
          target: "showingBloodPanelList",
          actions: {
            type: "addBloodPanelListResponseToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "getBloodPanelList",
      },
    },
    showingBloodPanelDetails: {
      on: {
        BACK_BUTTON_CLICKED: [
          {
            target: "exit",
            guard: {
              type: "ifHasBloodPanelIdInQueryParams",
            },
          },
          {
            target: "showingBloodPanelList",
          },
        ],
        BUY_NOW_BUTTON_CLICKED: {
          target: "showingPrompt",
        },
      },
    },
    error: {
      type: "final",
    },
    showingBloodPanelList: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "exit",
        },
        BLOOD_PANEL_SELECTED: {
          target: "fetchingBloodPanelDetails",
          actions: { type: "addSelectedBloodPanelToContext" },
        },
      },
    },
    fetchingBloodPanelDetails: {
      invoke: {
        src: "getBloodPanelDetails",
        onDone: {
          target: "showingBloodPanelDetails",
          actions: {
            type: "addBloodPanelDetailsResponseToContext",
          },
        },
        onError: {
          target: "error",
        },
      },
    },
    exit: {
      type: "final",
    },
    showingPrompt: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "showingBloodPanelDetails",
        },
        CONTINUE_BUTTON_CLICKED: {
          target: "creatingOrder",
        },
      },
    },
    creatingOrder: {
      invoke: {
        input: {},
        onDone: {
          target: "breakoutScreen",
          actions: {
            type: "addOrderDetailsToContext",
          },
        },
        src: "createOrder",
      },
    },
    breakoutScreen: {
      on: {
        USER_REDIRECTRED: {
          target: "waitingforOrderOutcome",
        },
      },
    },
    waitingforOrderOutcome: {
      after: {
        "500": {
          target: "fetchingOrderDetails",
        },
      },
    },
    fetchingOrderDetails: {
      invoke: {
        id: "fetchOrderDetails",
        input: {
          orderId: "string",
        },
        onDone: [
          {
            target: "paymentFailed",
            guard: {
              type: "paymentHasFailed",
            },
          },
          {
            target: "orderDetails",
            guard: {
              type: "hasOrderNumber",
            },
          },
          {
            target: "pendingOrderDetails",
            guard: {
              type: "hasNoOrderNumber",
            },
          },
          {
            target: "pendingOrderDetails",
            guard: {
              type: "timeOutExceeded",
            },
          },
          {
            target: "fetchingOrderDetails",
          },
        ],
        src: "fetchOrderDetails",
      },
    },
    paymentFailed: {},
    orderDetails: {},
    pendingOrderDetails: {},
  },
});
