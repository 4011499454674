import { useMachine } from "@xstate/react";
import { eventNames, ThirdPartyMedicalAssistanceFlowMachine } from "./machine";
import { ThirdPartyConsultationTypeSelection } from "../ThirdPartyConsultationTypeSelection";
import { MemberProductPaymentRatesChargeCode } from "@/services/core-api-adapter";
import { ThirdPartyMedicalAssistanceServicePrompt } from "../ThirdPartyMedicalAssistanceServicePrompt";
import { Navigate } from "react-router";

export default function ThirdPartyMedicalAssistanceFlow() {
  const [machineState, machineDispatch] = useMachine(
    ThirdPartyMedicalAssistanceFlowMachine
  );

  function onBackButtonClick() {
    machineDispatch({
      type: eventNames.BACK_BUTTON_CLICKED,
    });
  }

  function onContinueButtonClick() {
    machineDispatch({
      type: eventNames.CONTINUE_BUTTON_CLICKED,
    });
  }

  function onConsultationTypeSelected(
    service: MemberProductPaymentRatesChargeCode
  ) {
    machineDispatch({
      type: eventNames.CONTINUE_BUTTON_CLICKED,
      data: service,
    });
  }

  return (
    <>
      {machineState.matches("selectingConsultationType") &&
        machineState.context.productPaymentRatesResponse && (
          <ThirdPartyConsultationTypeSelection
            productPaymentRates={
              machineState.context.productPaymentRatesResponse
            }
            onNurseConsultClick={() =>
              onConsultationTypeSelected(
                MemberProductPaymentRatesChargeCode.NURSE_CHAT
              )
            }
            onDoctorConsultClick={() =>
              onConsultationTypeSelected(
                MemberProductPaymentRatesChargeCode.REMOTE_DOCTOR_CONSULTATIONS
              )
            }
            onLaboratoryServicesClick={() =>
              onConsultationTypeSelected(
                MemberProductPaymentRatesChargeCode.LABORATORY
              )
            }
            onPharmacyServicesClick={() =>
              onConsultationTypeSelected(
                MemberProductPaymentRatesChargeCode.PHARMACY
              )
            }
            onClinicAppointmentClick={() =>
              onConsultationTypeSelected(
                MemberProductPaymentRatesChargeCode.CLINIC
              )
            }
          />
        )}

      {machineState.matches("showingPrompt") &&
        machineState.context.selectedConsultationType && (
          <ThirdPartyMedicalAssistanceServicePrompt
            onContinueButtonClick={onContinueButtonClick}
            onBackButtonClick={onBackButtonClick}
            serviceType={machineState.context.selectedConsultationType}
          />
        )}

      {machineState.matches("exit") && <Navigate to="/"></Navigate>}
    </>
  );
}
