import {
  submitHealthRecordEvent,
  SubmitHealthRecordEventType,
  getMemberProductPaymentRates,
  MemberProductPaymentRatesItem,
} from "@/services/core-api-adapter";
import { setup, assign, fromPromise } from "xstate";

interface Context {
  selectedConsultationType: MemberProductPaymentRatesItem | null;
  productPaymentRatesResponse: MemberProductPaymentRatesItem[] | null;
}

export const eventNames = {
  CONTINUE_BUTTON_CLICKED: "CONTINUE_BUTTON_CLICKED",
  BACK_BUTTON_CLICKED: "BACK_BUTTON_CLICKED",
};

const initialContextValues: Context = {
  selectedConsultationType: null,
  productPaymentRatesResponse: [],
};

export const ThirdPartyMedicalAssistanceFlowMachine = setup({
  types: {
    context: {} as Context,
    events: {} as any,
  },
  actions: {
    addSelectedConsultationTypeToContext: assign(({ event, context }) => {
      const selectedItem =
        context.productPaymentRatesResponse?.find(
          (item) => item.chargeCode === event.data
        ) || null;
      return { selectedConsultationType: selectedItem };
    }),
    addProductPaymentRatesResponseToContext: assign(({ event }) => {
      return { productPaymentRatesResponse: event.output };
    }),
  },
  actors: {
    getProductPaymentRates: fromPromise(async () => {
      return await getMemberProductPaymentRates();
    }),
    submitHealthRecordEvent: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return await submitHealthRecordEvent(
          input.context.selectedConsultationType
            ?.chargeCode as unknown as SubmitHealthRecordEventType
        );
      }
    ),
  },
}).createMachine({
  context: initialContextValues,
  id: "ThirdPartyMedicalAssistanceFlow",
  initial: "fetchingProductPaymentRates",
  states: {
    fetchingProductPaymentRates: {
      entry: assign(initialContextValues),
      invoke: {
        id: "getProductPaymentRates",
        src: "getProductPaymentRates",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "selectingConsultationType",
          actions: "addProductPaymentRatesResponseToContext",
        },
        onError: {
          target: "error",
        },
      },
    },
    selectingConsultationType: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "showingPrompt",
          actions: { type: "addSelectedConsultationTypeToContext" },
        },
      },
    },
    showingPrompt: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "selectingConsultationType",
        },
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "submittingHealthRecordEvent",
        },
      },
    },
    submittingHealthRecordEvent: {
      invoke: {
        id: "submittingHealthRecordEvent",
        src: "submitHealthRecordEvent",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "exit",
        },
        onError: {
          target: "exit",
        },
      },
    },
    exit: {
      type: "final",
    },
    error: {
      type: "final",
    },
  },
});
