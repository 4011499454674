import { useContext } from "react";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { SnackbarContext } from "../../../../context/SnackbarProvider";
import SlideWithTransition from "../SlideWithTransition";
import { useTranslation } from "react-i18next";
import IconLoader from "../IconLoader";

export default function DefaultSnackbar() {
  const { snackbarState, hideSnackbar } = useContext(SnackbarContext);
  const { t } = useTranslation();

  return (
    <Snackbar
      open={snackbarState.visible}
      anchorOrigin={{
        horizontal: snackbarState.position?.horizontal || "center",
        vertical: snackbarState.position?.vertical || "top",
      }}
      autoHideDuration={snackbarState.duration}
      TransitionComponent={
        snackbarState.animate ? SlideWithTransition : undefined
      }
      TransitionProps={
        { direction: snackbarState.direction || "down" } as TransitionProps
      }
      onClose={() => hideSnackbar()}
    >
      <Alert
        onClose={() => hideSnackbar()}
        aria-label={snackbarState.message}
        severity={snackbarState.type}
        sx={{ width: "100%" }}
        action={
          <IconButton
            onClick={() => hideSnackbar()}
            sx={{ p: 0 }}
            aria-label={t("common.dismiss")}
          >
            <IconLoader icon="CrossIcon" sx={{ p: 0.2, mr: 0.5 }} />
          </IconButton>
        }
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
}
