import {
  trackEvent,
  trackPageViewV2,
  trackUserInteraction,
} from "@/services/analytics-adapter";
import { getConfigurationVariable } from "@/services/configuration-adapter";
import {
  isFeatureEnabled,
  FeatureToggleIdentifier,
} from "@/services/feature-toggle-adapter";
import {
  defaultFullscreenPageStyling,
  pageWithBottomNavigationStyling,
  overrideDefaultLinkStyling,
} from "@/theme";
import { buildWhatsAppUniversalLinkURL } from "@/utils";
import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import IconLoader from "@/features/Common/components/IconLoader";
import Panel from "@/features/Common/components/Panel";
import BottomNavigation from "../../../Common/components/BottomNavigation";

const SUPPORT_VOICE_PHONE_NUMBER_ZA =
  getConfigurationVariable(
    "VITE_APP_UNU_HEALTH_SUPPORT_VOICE_PHONE_NUMBER_ZA"
  ) || "";

const SUPPORT_WHATSAPP_PHONE_NUMBER_ZA =
  getConfigurationVariable(
    "VITE_APP_UNU_HEALTH_SUPPORT_WHATSAPP_PHONE_NUMBER_ZA"
  ) || "";

export default function GetSupport() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onWhatsAppChatClick() {
    trackEvent({ event: "action.whatsAppChat", source: "get support screen" });
    trackUserInteraction({
      linkText: "Get support | whatsapp chat button click",
      linkIntent: "query",
      linkScope: "button",
    });
  }

  function onCallUsClick() {
    trackEvent({ event: "action.callUs", source: "get support screen" });

    trackUserInteraction({
      linkText: "Get support | call us button click",
      linkIntent: "query",
      linkScope: "button",
    });
  }

  function onLogQueryClick(event: any) {
    if (
      isFeatureEnabled(FeatureToggleIdentifier.ENABLE_GET_HELP_LOG_QUERY_FORM)
    ) {
      event.preventDefault();
      trackEvent({
        event: "action.logAQuery",
        source: "get support screen",
      });
      return navigate("/get-help/log-query", {
        state: { eventSource: window.location.pathname },
      });
    }
    trackUserInteraction({
      linkText: "Get support | log a query button click",
      linkIntent: "query",
      linkScope: "button",
    });
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "More:Get support",
      pageSubSection1: "More",
      pageSubSection2: "More:Get support",
      pageCategory: "More",
    });
  }, []);

  return (
    <Stack
      sx={{
        ...defaultFullscreenPageStyling,
        ...pageWithBottomNavigationStyling,
      }}
    >
      <Stack spacing={4}>
        <Typography variant="h2">{t("GetSupportPage.title")}</Typography>
        <Typography variant="body1" color="neutral">
          {t("GetSupportPage.body")}
        </Typography>

        <Stack spacing={2}>
          <Typography>{t("GetSupportPage.subtitle")}</Typography>
          <Panel padding={0}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              component="a"
              href={buildWhatsAppUniversalLinkURL(
                SUPPORT_WHATSAPP_PHONE_NUMBER_ZA
              )}
              onClick={onWhatsAppChatClick}
              target="_blank"
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography variant="h4">
                {t("GetSupportPage.whatsAppChat")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>

          <Panel padding={0}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              component="a"
              href={`tel:${SUPPORT_VOICE_PHONE_NUMBER_ZA}`}
              onClick={onCallUsClick}
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography variant="h4">
                {t("GetSupportPage.callUs", {
                  phoneNumber: SUPPORT_VOICE_PHONE_NUMBER_ZA,
                })}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>

          <Panel padding={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              p={2}
              onClick={onLogQueryClick}
              component="a"
              target="_blank"
              href={getConfigurationVariable("VITE_APP_UNU_HEALTH_SUPPORT_URI")}
              sx={{
                ...overrideDefaultLinkStyling,
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="h4">
                {t("GetSupportPage.logQuery")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>
        </Stack>
      </Stack>

      <BottomNavigation />
    </Stack>
  );
}
